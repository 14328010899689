import { defineStore } from "pinia";
import { waitForMessage } from "~/helpers/inactivityTimeout";

type ReadyPayload = {
	state?: string;
};
export const useCwmpStore = defineStore("cwmp", () => {
	let cwmpWindow: Window | null;
	const cwmpUrl = ref<string>("");
	const cwmpOrigin = ref<string>("");
	// Theme may be SMIA or UMA. It is used to determine the style of the CWMP "Are you still here?" overlay.
	const theme = ref<string>("");
	const umaPrimary = ref<string>("#033A4F");
	const umaSecondary = ref<string>("#A21D21");
	const smiaPrimary = ref<string>("#45295C");
	const smiaSecondary = ref<string>("#FD734D");

	const setTheme = (themeParam: string) => {
		if (themeParam === "UMA" || themeParam === "SMIA") {
			theme.value = themeParam;
		} else {
			throw new Error("Invalid theme");
		}
	};
	const getTheme = () => {
		return theme.value;
	};
	/**
	 * Sets the theme color based on the provided theme parameter and theme key.
	 *
	 * @param {string} themeParam - The theme parameter, which should be either "UMA" or "SMIA".
	 * @param {string} colorKey - The key representing the specific color to set within the theme.
	 *  Allowed valued are "primary" and "secondary".
	 * @throws {Error} Throws an error if the theme parameter is invalid.
	 *
	 * Default colors are:
	 * UMA
	 *   Primary - Blue - #033A4F
	 *       gradient: #033A4F80 rgba(3, 58, 79, 0.5) - typo version: rgba(4, 58, 79, 0.5) #043a4f80
	 *   Secondary - Red - #A21D21
	 * SMIA
	 *   Primary - Purple - #45295C
	 *       gradient: rgb(69, 41, 92, 0.5) #45295c80
	 *   Secondary - Orange - #FD734D
	 */
	const setThemeColor = (themeParam: string, colorKey: string) => {
		if (themeParam === "UMA") {
			switch (colorKey) {
			case "primary":
				return umaPrimary.value;
			case "secondary":
				return umaSecondary.value;
			default:
				throw new Error("Invalid color key");
			}
		} else if (themeParam === "SMIA") {
			switch (colorKey) {
			case "primary":
				return smiaPrimary.value;
			case "secondary":
				return smiaSecondary.value;
			default:
				throw new Error("Invalid color key");
			}
		} else {
			throw new Error("Invalid theme");
		}
	};
	/**
	 * Returns the theme color based on the provided color key and the current theme.
	 * @param {string} colorKey
	 * @throws {Error} Throws an error if the color key is invalid.
	 */
	const getThemeColor = (colorKey: string) => {
		if (theme.value === "UMA") {
			switch (colorKey) {
			case "primary":
				return umaPrimary.value;
			case "secondary":
				return umaSecondary.value;
			default:
				throw new Error("Invalid color key");
			}
		} else if (theme.value === "SMIA") {
			switch (colorKey) {
			case "primary":
				return smiaPrimary.value;
			case "secondary":
				return smiaSecondary.value;
			default:
				throw new Error("Invalid color key");
			}
		}
	};
	const getCwmpOrigin = () => {
		return cwmpOrigin.value;
	};
	const getCwmpUrlSettings = () => {
		if (!window || typeof window === "undefined" || !process.client) {
			console.debug("window is not defined3");
			return "";
		}
		switch (window?.location?.hostname) {
		case "localhost":
			cwmpUrl.value = "http://localhost:3001/";
			cwmpOrigin.value = "http://localhost:3001";
			break;
			// dev
		case "dev01.myunitedmedicareadvisors.com":
			cwmpUrl.value =
					"https://dev01-policyconcierge.myunitedmedicareadvisors.com/";
			cwmpOrigin.value =
					"https://dev01-policyconcierge.myunitedmedicareadvisors.com";
			break;
		case "dev01.mysmartmatch.com":
		case "benefits-portal.dev01-svg.com":
			cwmpUrl.value = "https://dev01-policyconcierge.mysmartmatch.com/";
			cwmpOrigin.value = "https://dev01-policyconcierge.mysmartmatch.com";
			break;
			// stage
		case "stage.myunitedmedicareadvisors.com":
			cwmpUrl.value =
					"https://stage-policyconcierge.myunitedmedicareadvisors.com/";
			cwmpOrigin.value =
					"https://stage-policyconcierge.myunitedmedicareadvisors.com";
			break;
		case "stage.mysmartmatch.com":
		case "benefits-portal.stage-svg.com":
			cwmpUrl.value = "https://stage-policyconcierge.mysmartmatch.com/";
			cwmpOrigin.value = "https://stage-policyconcierge.mysmartmatch.com";
			break;
			// prod
		case "myunitedmedicareadvisors.com":
		case "www.myunitedmedicareadvisors.com":
		case "prod.myunitedmedicareadvisors.com":
			cwmpUrl.value = "https://policyconcierge.myunitedmedicareadvisors.com/";
			cwmpOrigin.value =
					"https://policyconcierge.myunitedmedicareadvisors.com";
			break;
		case "mysmartmatch.com":
		case "www.mysmartmatch.com":
		case "prod.mysmartmatch.com":
		case "benefits-portal.prod-svg.com":
			cwmpUrl.value = "https://policyconcierge.mysmartmatch.com/";
			cwmpOrigin.value = "https://policyconcierge.mysmartmatch.com";
			break;
		default:
			cwmpUrl.value = "http://localhost:3001/";
			cwmpOrigin.value = "http://localhost:3001";
		}
		return cwmpUrl.value;
	};

	// CBP should await this function before sending the Policy Message
	const launchCwmpWindow = async (window: Window) => {
		if (typeof window === "undefined" || !process.client) {
			console.debug(
				"launchCwmpWindow - window is not defined so not launching window",
			);
			return;
		}
		console.debug("launchCWMP");
		if (!cwmpWindow || cwmpWindow.closed) {
			const cwmpTempWindow = window.open(getCwmpUrlSettings(), "cwmp");
			console.debug(
				"launchCWMP - window launched - now waiting for CHAT_WITH_MY_POLICY_STATE",
			);
			const payload = (await waitForMessage(
				"CHAT_WITH_MY_POLICY_STATE",
				cwmpOrigin.value,
				20000,
			)) as ReadyPayload;
			if (payload?.state === "READY") {
				console.debug("launchCWMP - READY state received from CWMP");
				cwmpWindow = cwmpTempWindow;
				// The layout will catch this event and notify the InactivityTimekeeper that the CWMP app
				// can extend the CBP timeout.
				const event = new CustomEvent("cwmp-window-obtained", {
					detail: { window: cwmpWindow, origin: cwmpOrigin.value },
				});
				document.dispatchEvent(event);
			}
		}
	};

	const sendChatWithPolicy = (
		tenantToken: string,
		planId: string,
		zip: string,
		countyCode: string,
		planYear: string,
	) => {
		console.log(`>>>>about to sendChatWithPolicy to ${cwmpOrigin.value}`);
		if (cwmpWindow) {
			console.debug(`>>>>now sendChatWithPolicy to ${cwmpOrigin.value}`);
			cwmpWindow.postMessage(
				{
					action: "CHAT_WITH_POLICY",
					payload: {
						tenantToken: tenantToken,
						planId: planId,
						zip: zip,
						countyCode: countyCode,
						planYear: planYear,
					},
				},
				cwmpOrigin.value,
			);
			console.log(`>>>>after to sendChatWithPolicy to ${cwmpOrigin.value}`);
		}
	};

	// Call getCwmpUrlSettings to set initial values as a side effect.
	if (process.client && typeof window !== "undefined") {
		try {
			getCwmpUrlSettings();
		} catch (e) {
			console.debug("Error in cwmpStore with getCwmpUrlSettings: ", e);
		}
	}

	return {
		getTheme,
		setTheme,
		getThemeColor,
		setThemeColor,
		getCwmpOrigin,
		launchCwmpWindow,
		sendChatWithPolicy,
	};
});

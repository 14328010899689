import { defineStore } from "pinia";

import type { ApplicationPolicyResponse } from "@/models";
import { datadogRum } from "@datadog/browser-rum";

export const useDocumentStore = defineStore("document", () => {
	const apiHandler = $fetch.create({
		onResponseError({ response }) {
			console.log("response: ", response);

			if (response.status === 401) {
				// Save the current route before redirecting to the login page
				localStorage.setItem("redirectRoute", window.location.pathname);
			}
		},
	});

	const getDocument = async (
		plan_number: string,
		zip_code: string,
		county_code: string,
		plan_year: string,
	) => {
		try {
			const params = { plan_number, zip_code, county_code, plan_year };
			const response = await apiHandler<ApplicationPolicyResponse[]>(
				`/api/chat-svg-document/get-document`,
				{
					method: "GET",
					query: params,
				},
			);
			if (response && response.length === 1) {
				const [r] = response;
				if (!r.summary_of_benefits_url || !r.evidence_of_coverage_url) {
					datadogRum.addAction("Missing MedAdv Related Documents", {
						...params,
					});
				}
			}
			return response;
		} catch (error) {
			console.error("Error getting a Chat SVG Document: ", error);
		}
	};

	return {
		getDocument,
	};
});

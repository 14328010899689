type ChangeTimeoutsAction = {
	action: "CHANGE_TIMEOUTS";
	payload: {
		timeUntilStillThere: number;
		timeUntilAutoLogout: number;
	};
};

/**
 * This class is designed to handle inactivity timeout for a given app and also
 * to extend the timeout of a related app. The apps will communicate via Html PostMessage.
 * The activity in one app will not only extend the apps own timeout but also the timeout of the related app.
 * For example, the app could be Customer Benefits Portal(CBP) and the related app Chat With My Policy (CWMP)
 */
export class InactivityTimekeeper {
	// How much inactivity time until the still there screen is shown
	private inactivityTimeUntilStillThere: number = 0;
	// timer running until still there screen is shown
	private inactivityTimeUntilStillThereTimer: any = null;
	// How much additional time after the still there screen until auto-logout
	private inactivityTimeUntilAutoLogout: number = 0;
	// timer running from still there screen to auto-logout
	private inactivityTimeUntilAutoLogoutTimer: any = null;
	private lastActivityTime: any = new Date();
	private lastActivityTimeOfRelatedAppEvaluated: any = new Date();
	private windowEvents = ["focus", "blur", "resize"];
	private documentEvents = [
		"mousedown",
		"mousemove",
		"mouseup",
		"keydown",
		"keyup",
		"touchstart",
		"touchmove",
		"touchend",
		"scroll",
		"wheel",
	];
	private timekeeperName: string = "";
	private appAbbrev: string = "";
	private relatedAppAbbrev: string = "";
	private relatedAppTargetOrigin: string = "";
	private relatedAppWindow: Window | null = null;
	private printLastActivityTimeEach5SecondInterval: null | ReturnType<
		typeof setInterval
	> = null;

	/**
	 * @param timeKeeperName string - the name of the timekeeper,
	 *  usually appName--layoutName - such as 'chat-with-my-policy--default'
	 * @param appAbbrev string - the abbreviated name of the app such as CBP or CWMP
	 * @param inactivityTimeUntilStillThere number -
	 *   How much inactivity time until the still there screen is shown
	 * @param inactivityTimeUntilAutoLogout number -
	 *   How much additional time after the still there screen until auto-logout
	 */
	start = (
		timeKeeperName: string,
		appAbbrev: string,
		// Default Durations should be 5 minutes(300000) until "Still There",
		// then an additional 10 minutes(600000) to auto-logout
		// DURATIONS - 5 minutes(300000), 10 minutes(600000)
		inactivityTimeUntilStillThere: number = 300000,
		inactivityTimeUntilAutoLogout: number = 600000,
	) => {
		this.timekeeperName = timeKeeperName;
		this.appAbbrev = appAbbrev;
		this.inactivityTimeUntilStillThere = inactivityTimeUntilStillThere;
		this.inactivityTimeUntilAutoLogout = inactivityTimeUntilAutoLogout;
		this.addInactivityListeners();
		this.resetInactivityTimer();
		this.registerLastActivityTimeResponder();
		this.registerYesStillHereRelatedAppResponder();
		this.registerChangeTimeoutsResponder();
		this.consolePrintLastActivityTimeEach5Second();
	};

	/**
	 * This can be used to debug timing issues
	 * It will print the last activity time every 5 seconds
	 */
	consolePrintLastActivityTimeEach5Second = () => {
		this.printLastActivityTimeEach5SecondInterval = setInterval(() => {
			const currentTime = new Date();
			const timeDiff = currentTime.getTime() - this.lastActivityTime.getTime();
			const timeDiffRelated =
				currentTime.getTime() -
				this.lastActivityTimeOfRelatedAppEvaluated.getTime();
			console.debug(
				`****${this.namePrefix()}-consolePrintLastActivityTimeEach5Second - ` +
					`currentTime: ${currentTime} ` +
					`lastActivityTimeOfRelatedAppEvaluated: ${this.lastActivityTimeOfRelatedAppEvaluated} ` +
					`print timeDiffRelatedAppLastActivity: ${timeDiffRelated}` +
					`lastActivityTime: ${this.lastActivityTime} ` +
					`print timeDiffLastActivityThisApp: ${timeDiff} `,
			);
		}, 5000);
	};

	/**
	 * @param relatedAppAbbrev string - the related app to listen for timeout messages
	 * @param relatedAppTargetOrigin string - the target origin of the related app
	 * @param relatedAppWindow  window (optional) - a key name to find the related app window object
	 *  this will only be set on the app that launches the other window (CBP/cwmpClient) and not for cwmp.
	 *  key name will typically be 'cwmpWindowHandle' and will be stored in html sessionStorage.
	 */
	addRelatedApp = (
		relatedAppAbbrev: string,
		relatedAppTargetOrigin: string,
		relatedAppWindow: Window | null,
	) => {
		this.relatedAppAbbrev = relatedAppAbbrev;
		this.relatedAppTargetOrigin = relatedAppTargetOrigin;
		if (relatedAppWindow) {
			this.relatedAppWindow = relatedAppWindow;
		}
	};

	/**
	 * Checks to see if a related app exists and clears one if window becomes inactive
	 * @returns boolean - true if related app exists
	 */
	existsRelatedApp = () => {
		if (this.relatedAppWindow && this.relatedAppWindow.closed) {
			this.relatedAppWindow = null;
			this.relatedAppAbbrev = "";
			this.relatedAppTargetOrigin = "";
		}
		return this.relatedAppWindow != null;
	};

	stop = () => {
		clearTimeout(this.inactivityTimeUntilStillThereTimer);
		this.removeInactivityListeners();
		this.deregisterLastActivityTimeResponder();
		this.deregisterYesStillHereRelatedAppResponder();
		this.deregisterChangeTimeoutsResponder();
	};

	/**
	 * This means reset by a user saying they are still there
	 */
	reset = () => {
		this.resetInactivityTimer();
		this.addInactivityListeners();
		// Notify the related app to clear their "still there" window immediately
		this.relatedAppWindow?.postMessage(
			{
				action: `YES_STILL_HERE_FROM_${this.appAbbrev}`,
				payload: {},
			},
			this.relatedAppTargetOrigin,
		);
	};

	/**
	 * This function is used register the change timeouts responder
	 * This is used change the timeouts by sending a message via the Devtools console
	 */
	private registerChangeTimeoutsResponder = () => {
		window.addEventListener("message", this.changeTimeoutsResponder);
	};

	private deregisterChangeTimeoutsResponder = () => {
		window.removeEventListener("message", this.changeTimeoutsResponder);
	};

	/**
	 * Running these commands in the browser console will reset the timeouts.
	 *
	 * Run the commands this way in the browser console if targeting an iframe:
	 * const iframe = document.getElementById('targetFrame');
	 * const message = {
	 *   action: "CHANGE_TIMEOUTS",
	 *   payload: {
	 *     timeUntilStillThere: 15000,
	 *     timeUntilAutoLogout: 30000
	 *   }
	 * };
	 * iframe.contentWindow.postMessage(message, '*');
	 *
	 * If not running in an iframe then postMessage to the window
	 * const message = {
	 *   action: "CHANGE_TIMEOUTS",
	 *   payload: {
	 *     timeUntilStillThere: 15000,
	 *     timeUntilAutoLogout: 30000
	 *   }
	 * };
	 * window.postMessage(message, '*');
	 *
	 * Filter the browser devtools console log for:
	 * changeTimeoutsResponder
	 * in order to see the event come through.
	 *
	 * @param event Change Timeout Action
	 */
	private changeTimeoutsResponder = async (
		event: MessageEvent<ChangeTimeoutsAction>,
	) => {
		if (
			event.origin == "http://localhost:9000" ||
			event.origin == window.origin
		) {
			if (event.data.action === "CHANGE_TIMEOUTS") {
				const timeUntilStillThere = event.data.payload.timeUntilStillThere;
				const timeUntilAutoLogout = event.data.payload.timeUntilAutoLogout;
				console.debug(
					`****${this.namePrefix()}-changeTimeoutsResponder - ` +
						`Received CHANGE_TIMEOUTS event - timeUntilStillThere: ${timeUntilStillThere}, ` +
						`timeUntilAutoLogout: ${timeUntilAutoLogout}`,
				);
				this.inactivityTimeUntilStillThere = timeUntilStillThere;
				this.inactivityTimeUntilAutoLogout = timeUntilAutoLogout;
				this.resetInactivityTimerPartial();
				this.removeInactivityListeners();
				this.addInactivityListeners();
				this.removeStillThere();
			}
		}
	};

	/**
	 * This function is used to register the last activity time responder
	 * This is used to get the last activity time from the related app
	 */
	private registerLastActivityTimeResponder = () => {
		window.addEventListener("message", this.lastActivityTimeResponder);
	};

	private deregisterLastActivityTimeResponder = () => {
		window.removeEventListener("message", this.lastActivityTimeResponder);
	};

	/**
	 * This function is used to register the YesStillHere Related App responder
	 * This is used remove the still there screen when the related app says the user is still there
	 */
	private registerYesStillHereRelatedAppResponder = () => {
		window.addEventListener("message", this.YesStillHereRelatedAppResponder);
	};

	private deregisterYesStillHereRelatedAppResponder = () => {
		window.removeEventListener("message", this.YesStillHereRelatedAppResponder);
	};

	private lastActivityTimeResponder = (event: MessageEvent<any>) => {
		console.log("In lastActivityTimeResponser", event);
		if (event.origin == this.relatedAppTargetOrigin) {
			if (
				event.data.action ===
				`REQUEST_LAST_ACTIVITY_TIME_FROM_${this.appAbbrev}`
			) {
				console.debug(
					`****${this.namePrefix()}-lastActivityTimeResponder returning ${this.lastActivityTime} ` +
						`from ${this.appAbbrev} to ${this.relatedAppAbbrev}`,
				);
				const lastActivityTime = this.lastActivityTime;
				if (!event.source) return;
				event?.source.postMessage(
					{
						action: `FROM_${this.appAbbrev}_LAST_ACTIVITY_TIME_RESPONSE`,
						payload: {
							lastActivityTime: lastActivityTime,
						},
					},
					// (event.source.postMessage ts definitions are missing the target origin param so 'as any' is the workaround)
					event.origin as any, // Suppress the TypeScript error
				);
			}
		}
	};

	private YesStillHereRelatedAppResponder = (event: MessageEvent<any>) => {
		if (event.origin == this.relatedAppTargetOrigin) {
			if (
				event.data.action === `YES_STILL_HERE_FROM_${this.relatedAppAbbrev}`
			) {
				console.debug(
					`****${this.namePrefix()}-YesStillHereRelatedAppResponder - ` +
						`Received YES_STILL_HERE_FROM_${this.relatedAppAbbrev} from ${this.relatedAppAbbrev} ` +
						`to ${this.appAbbrev}`,
				);
				this.resetInactivityTimerPartial();
				this.addInactivityListeners();
				this.removeStillThere();
			}
		}
	};

	private resetInactivityTimerHandler = () => {
		this.resetInactivityTimer();
	};

	/**
	 * This function handles user activity extending the timeout
	 */
	private resetInactivityTimer = () => {
		this.lastActivityTime = new Date();
		console.debug(
			`**********Setting lastActivityTime to ${this.lastActivityTime} `,
		);
		// console.trace()
		clearTimeout(this.inactivityTimeUntilStillThereTimer);
		clearTimeout(this.inactivityTimeUntilAutoLogoutTimer);
		const millisecondsToWait = this.inactivityTimeUntilStillThere;
		console.debug(
			`****${this.namePrefix()}-resetInactivityTimer:` +
				`time: ${new Date()} millisecondsToWait: ${millisecondsToWait}`,
		);
		this.inactivityTimeUntilStillThereTimer = setTimeout(
			this.checkBeforeStillThere,
			this.inactivityTimeUntilStillThere,
			this.inactivityTimeUntilAutoLogout,
		);
	};

	/**
	 * This function is used to reset the inactivity timer with the time remaining from the related app
	 * It does not set the lastActivityTime which is used when the related app activity extends the timeout
	 * The lastActivityTime will represent this apps last activity time, not the related app.
	 */
	private resetInactivityTimerPartial = (
		millisecondsOfInactivityUsed: number = 0,
	) => {
		// console.trace()
		clearTimeout(this.inactivityTimeUntilStillThereTimer);
		clearTimeout(this.inactivityTimeUntilAutoLogoutTimer);
		let millisecondsToWait =
			this.inactivityTimeUntilStillThere - millisecondsOfInactivityUsed;
		if (millisecondsToWait < 0) {
			millisecondsToWait = 0;
		}
		console.debug(
			`****${this.namePrefix()}-resetInactivityTimerPartial:` +
				`time: ${new Date()} millisecondsToWait: ${millisecondsToWait}`,
		);
		this.inactivityTimeUntilStillThereTimer = setTimeout(
			this.checkBeforeStillThere,
			millisecondsToWait,
			this.inactivityTimeUntilAutoLogout,
		);
	};

	private addInactivityListeners = () => {
		this.windowEvents.forEach((name) => {
			window.addEventListener(name, this.resetInactivityTimerHandler, true);
		});
		this.documentEvents.forEach((name) => {
			document.addEventListener(name, this.resetInactivityTimerHandler, true);
		});
	};

	private removeInactivityListeners = () => {
		this.windowEvents.forEach((name) => {
			window.removeEventListener(name, this.resetInactivityTimerHandler, true);
		});
		this.documentEvents.forEach((name) => {
			document.removeEventListener(
				name,
				this.resetInactivityTimerHandler,
				true,
			);
		});
	};

	private showStillThere = (millisecondsUntilAutoLogout: number) => {
		console.debug(
			`****${this.namePrefix()}-showStillThere` +
				`time: ${new Date()} millisecondsUntilAutoLogout: ${millisecondsUntilAutoLogout}`,
		);
		this.emitEvent("inactivity-timekeeper-show-still-there", {
			app: this.appAbbrev,
			show: true,
		});
		this.removeInactivityListeners();
		clearTimeout(this.inactivityTimeUntilStillThereTimer);
		this.inactivityTimeUntilAutoLogoutTimer = setTimeout(
			this.checkBeforeAutoLogout,
			millisecondsUntilAutoLogout,
		);
	};

	private removeStillThere = () => {
		console.debug(`****${this.namePrefix()}-removeStillThere`);
		this.emitEvent("inactivity-timekeeper-show-still-there", {
			app: this.appAbbrev,
			show: false,
		});
	};

	private getRelatedAppLastActivityTime = async (): Promise<Date | null> => {
		console.debug(`****${this.namePrefix()}-getRelatedAppLastActivityTime`);
		return new Promise<Date | null>((resolve, _) => {
			waitForMessage(
				`FROM_${this.relatedAppAbbrev}_LAST_ACTIVITY_TIME_RESPONSE`,
				this.relatedAppTargetOrigin,
			)
				.then((payload: any) => {
					console.debug(
						`getRelatedAppLastActivityTime - ` +
							`Received lastActivityTime ${payload?.lastActivityTime} ` +
							`from ${this.relatedAppAbbrev} in ${this.appAbbrev}`,
					);
					if (payload?.lastActivityTime) {
						resolve(new Date(payload.lastActivityTime));
					} else {
						resolve(null);
					}
				})
				.catch((error: any) => {
					console.debug(
						`In getRelatedAppLastActivityTime - caught error: ${error}`,
					);
					// timeout or error
					resolve(null);
				});
			// request the response
			if (this.relatedAppWindow) {
				try {
					this.relatedAppWindow.postMessage(
						{
							action: `REQUEST_LAST_ACTIVITY_TIME_FROM_${this.relatedAppAbbrev}`,
							payload: {},
						},
						this.relatedAppTargetOrigin,
					);
				} catch (error) {
					console.error(
						`In getRelatedAppLastActivityTime - error posting REQUEST_LAST_ACTIVITY_TIME_FROM_${this.relatedAppAbbrev} message: ${error}`,
					);
				}
			} else {
				console.debug(
					`In getRelatedAppLastActivityTime - relatedAppWindow is null`,
				);
				resolve(null);
			}
		});
	};

	/**
	 * This evaluates the lastActivityTime of the related app and sets it to a long default
	 * when no related app lastActivityTime is available
	 */
	private evaluateLastActivityTime = (lastActivityTime: Date | null) => {
		let timeToReturn;
		if (!lastActivityTime) {
			const currentDate = new Date();
			// Set the last activity time in this function of the related app to greater than the max timeout
			// since we did not get a last activity time from the related app
			timeToReturn = new Date(
				currentDate.getTime() -
					this.inactivityTimeUntilStillThere -
					this.inactivityTimeUntilAutoLogout -
					10000,
			);
		} else {
			timeToReturn = lastActivityTime;
		}
		this.lastActivityTimeOfRelatedAppEvaluated = timeToReturn;
		console.debug(
			`evaluateLastActivityTime ${lastActivityTime} - returning ${timeToReturn}`,
		);
		return timeToReturn;
	};
	/**
	 * Determine if activity in related app should postpone display of the Still There screen
	 */
	private checkBeforeStillThere = async () => {
		console.debug(`****${this.namePrefix()}-checkBeforeStillThere`);
		if (!this.existsRelatedApp()) {
			// There is no other app activity to check so just show still there
			this.showStillThere(this.inactivityTimeUntilAutoLogout);
			return;
		}
		let lastActivityTime = await this.getRelatedAppLastActivityTime();
		console.debug(
			`checkBeforeStillThere - relatedAppLastActivityTime is ${lastActivityTime}`,
		);
		lastActivityTime = this.evaluateLastActivityTime(lastActivityTime);
		console.debug(
			`checkBeforeStillThere - relatedAppLastActivityTime after evaluation is ${lastActivityTime}`,
		);
		const currentNow = new Date();
		console.debug(`checkBeforeStillThere - currentNow is ${currentNow}`);
		const timeDiff = currentNow.getTime() - lastActivityTime.getTime();
		console.debug(`checkBeforeStillThere - timediff is ${timeDiff}`);
		if (timeDiff > this.inactivityTimeUntilStillThere) {
			console.debug(`checkBeforeStillThere - calling showStillThere`);
			this.showStillThere(this.inactivityTimeUntilAutoLogout);
		} else {
			console.debug(
				`checkBeforeStillThere - calling resetInactivityTimer with ${timeDiff}`,
			);
			// Related app activity has extended the timeout so reset the timer to the related app remaining time
			this.resetInactivityTimerPartial(timeDiff);
			this.addInactivityListeners();
			this.removeStillThere();
		}
	};

	/**
	 * Determine if activity in related app should suppress auto-logout
	 */
	private checkBeforeAutoLogout = async () => {
		console.debug(`****${this.namePrefix()}-checkBeforeAutoLogout`);
		if (!this.existsRelatedApp()) {
			// There is no other app activity to check so just auto logout
			this.autoLogout();
			return;
		}
		let lastActivityTime = await this.getRelatedAppLastActivityTime();
		console.debug(
			`checkBeforeAutoLogout - relatedAppLastActivityTime is ${lastActivityTime}`,
		);
		lastActivityTime = this.evaluateLastActivityTime(lastActivityTime);
		console.debug(
			`checkBeforeAutoLogout - relatedAppLastActivityTime after evaluation is ${lastActivityTime}`,
		);
		const timeDiff = new Date().getTime() - lastActivityTime.getTime();
		console.debug(
			"timeDiff in checkBeforeAutoLogout (now - relatedAppLastActivityTime):",
			timeDiff,
		);
		// if payload.lastActivityTime is greater than the max, auto logout
		const maxInactivityTime =
			this.inactivityTimeUntilStillThere + this.inactivityTimeUntilAutoLogout;
		console.debug(
			"maxInactivityTime in checkBeforeAutoLogout:",
			maxInactivityTime,
		);
		if (timeDiff > maxInactivityTime) {
			console.debug(
				"checkBeforeAutoLogout - logging out because timediff is greater than max Inactivity time",
			);
			this.autoLogout();
		} else {
			if (timeDiff < this.inactivityTimeUntilStillThere) {
				console.debug(
					"checkBeforeAutoLogout - removeStillThere, resetInactivityTimout in checkBeforeAutoLogout",
				);
				this.resetInactivityTimerPartial(timeDiff);
				this.addInactivityListeners();
				this.removeStillThere();
			} else {
				console.debug(
					"checkBeforeAutoLogout - showStillThere in checkBeforeAutoLogout",
				);
				this.showStillThere(timeDiff);
			}
		}
	};

	private autoLogout = () => {
		console.debug(`****${this.namePrefix()}-autoLogout`);
		this.emitEvent("inactivity-timekeeper-auto-logout", {
			app: this.appAbbrev,
		});
		this.removeStillThere();
		clearInterval(
			this.printLastActivityTimeEach5SecondInterval as ReturnType<
				typeof setInterval
			>,
		);
	};

	private namePrefix = () => {
		return `${this.appAbbrev}-${this.timekeeperName}`;
	};

	// Notification to pages hosting the InactivityTimekeeper
	private emitEvent(eventName: string, detail?: any) {
		const event = new CustomEvent(eventName, { detail });
		document.dispatchEvent(event);
	}
}

export const waitForMessage = (
	expectedAction: string,
	expectedOrigin: string,
	timeout = 5000,
) => {
	console.debug("waitForMessage - expectedAction:", expectedAction);
	return new Promise((resolve, reject) => {
		console.debug("waitForMessage - creating promise");
		let timeoutId: ReturnType<typeof setTimeout> | null = null;
		const messageHandler = (event: MessageEvent) => {
			console.debug("waitForMessage - event:", event);
			if (event.origin == expectedOrigin) {
				// Check if the message matches the expected action
				if (event.data.action === expectedAction) {
					if (timeoutId != null) {
						clearTimeout(timeoutId);
					}
					window.removeEventListener("message", messageHandler);
					resolve(event.data.payload); // Resolve with the message
				}
			}
		};

		// Set up the timeout
		timeoutId = setTimeout(() => {
			window.removeEventListener("message", messageHandler);
			console.debug(`****-Timeout waiting for message ${expectedAction}`);
			reject(new Error(`****-Timeout waiting for message ${expectedAction}`));
		}, timeout);

		// Listen for the message
		console.debug("waitForMessage - adding event listener");
		window.addEventListener("message", messageHandler);
	});
};
